// Global Stylesheets
import '@/portal/reset.css'
import '@/portal/print.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-contexify/dist/ReactContexify.min.css'
import '@/portal/react-contexify-overrides.css'

// This file pulls in the base antd CSS which has
// some sensible defaults so we don't get a flash
// of unstyled if/when we are lazy loading antd
// components
import 'antd/dist/antd.less'
import '@narratorai/antd-custom/src/antd-custom.less'

import React from 'react'

import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import Head from 'next/head'
import { AppProps as NextAppProps } from 'next/app'
import Router from 'next/router'
import { NextComponentType, NextPageContext } from 'next'

import { isClient } from '@/util/env'
import { makeTitle } from '@/util/title'

// Initializes sentry
import '@/util/sentry'

import analytics from '@/portal/util/analytics'

// Record a pageview when route changes
Router.events.on('routeChangeComplete', () => {
  analytics.page()
})

if (isClient && process.env.NEXT_PUBLIC_LOGROCKET_ID) {
  // Note: init '5nc1c7/portal-localhost' instead for for local debugging
  LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_ID, {
    release: process.env.BUILD_ID,

    // You may exempt elements from being automatically sanitized by adding the data-public attribute to them.
    dom: {
      textSanitizer: true,
      inputSanitizer: true,
    },
    network: {
      requestSanitizer: (request) => {
        // scrub request bodies
        // TODO we could selectively allow some request bodies through
        request.body = undefined

        // scrub auth header
        request.headers['Authorization'] = undefined

        return request
      },
      responseSanitizer: (response) => {
        // scrub response body
        // TODO we could selectively allow some response bodies through
        response.body = undefined
        return response
      },
    },
  })
  setupLogRocketReact(LogRocket)
}

// Setup Monaco workers

// NOTE these files are symlinked from the published the-sequel package into `public/.monaco/`
// If you ever need to add more, add symlinks like so and then update the getWorkerUrl function below:
//  cd public/.monaco
//  ln -s ../../node_modules/@narratorai/the-sequel/dist/example.worker.js example.worker.js
if (isClient) {
  window.MonacoEnvironment = {
    getWorkerUrl: function (_: any, label: string): string {
      if (label === 'json') {
        return '/.monaco/json.worker.js'
      }
      return '/.monaco/editor.worker.js'
    },
  }
}

// https://nextjs.org/docs/advanced-features/measuring-performance
// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   // TODO do something with these!
//   // console.log(metric)
// }

const DEFAULT_LAYOUT = ({ children }: React.PropsWithChildren<unknown>) => children

// This app component implements a dynamic layout pattern
// Any page component can specify a static "Layout" property to override
// see https://github.com/vercel/next.js/tree/canary/examples/with-dynamic-app-layout

interface AppProps extends NextAppProps {
  err?: any
  Component: NextComponentType<NextPageContext, any> & {
    Layout?: React.ComponentType
  }
}

const App: React.FC<AppProps> = ({ Component, pageProps, err }) => {
  const Layout = (Component.Layout || DEFAULT_LAYOUT) as React.ComponentType

  React.useEffect(() => {
    if (!window.Cypress) {
      // Register the service worker, except when running E2E tests
      window.workbox?.register()
    }
  }, [])

  return (
    <Layout>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5.0, minimum-scale=0.75"
        ></meta>
        <title>{makeTitle(pageProps.title)}</title>
      </Head>

      {/* err prop is workaround for https://github.com/vercel/next.js/issues/8592 */}
      <Component {...pageProps} err={err} />
    </Layout>
  )
}

export default App
